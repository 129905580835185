<template>
  <div>
    <v-select
      label="text"
      :options="options"
      v-model="rowData.roomID"
      :reduce="text => text.value"
      :clearable="false"
      :searchable="false"
    >
      <template #no-options>
        {{$t('common.no_options')}}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common from "@/common";

export default {
  name: "SlotRoom",
  components: {
    vSelect
  },
  props: {
    rowData: {type: Object},
    floorId: {type: Number}
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    floorId: function (floorID, oldFloorID) {
      if (oldFloorID) {
        this.rowData.roomID = null
        this.options = []
      }

      this.getRoomOptions(floorID)
    }
  },
  methods: {
    getRoomOptions: function (floor_id) {
      common.getRoomOptions(floor_id).then(res => {
        this.options = res
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
